import React from 'react';
import {Form, Row, Col} from 'react-bootstrap'

const GroupingFilter = ({show, orderGroupingFilter, handleOnGroupingChange, orderGroupings, withLabels}) => {

  return (
    <Col>
      <Form.Group as={Row} >
        {withLabels && <Form.Label column sm={3}>Group</Form.Label>}
        <Col>
          <Form.Control as="select" value={orderGroupingFilter} onChange={handleOnGroupingChange}>
            <option key="firstInLine" value=''>--Select Group--</option>
            {
              orderGroupings && Array.isArray(orderGroupings) ? orderGroupings.map(x => {
                return (
                  <option key={x.groupName}>{x.groupName}</option>
                )
              }) : <></>
            }
          </Form.Control>
        </Col>
      </Form.Group>
    </Col>
  )
}

export default GroupingFilter