import React from 'react';
import styles from './rightSidebar.module.css'
import { Row, Col, Container, Card, Button } from "react-bootstrap"

const RightSidebar = ({show, children, closeFn, state}) => {

  return (
    <>
      <Container className={`${styles.wrapper} ${show ? styles.show : ''} pt-4`}>
        <Row>
          <Col className='text-left mb-2'>
            <Button variant="outline-primary" onClick={closeFn}><span className="oi oi-circle-x"></span> Close</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {children}
          </Col>
        </Row>
        {/* {state ? JSON.stringify(state) : ''} */}
      </Container>
    </>
  )
}

export default RightSidebar