import React, {useState, useEffect} from 'react';
import Layout from "../../../components/layout"
import {Container, Table, Button, Row, Col, Form, Badge} from 'react-bootstrap'
import SEO from "../../../components/seo";
import Loader from '../../../components/loading';
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import OrderItemBill from '../../../components/orderItemBill'
import StandardModal from '../../../components/modal';
import {generateMonths} from '../../../helpers/dateTime';


const ReportAdminSuppliersBill = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [billArray, setBillArray] = useState([])
  const [billArrayCopy, setBillArrayCopy] = useState([])
  const [itemId, setItemId] = useState('0');
  const [showDetails, setShowDetails] = useState(false);
  const [showSummary, setShowSummary] = useState(true);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [niceNames, setNicenames] = useState([]);
  const [chosenSupplierName, setChosenSupplierName] = useState('');
  const [month, setMonth] = useState('');
  const [months, setMonths] = useState(generateMonths(6));
  const [itemType, setItemType] = useState('');
  const [itemTypes, setItemTypes] = useState(constants.ITEM_TYPES);

  const getSuppliersBill = () => {
    generateMonths();
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/suppliers-bill`

    if (chosenSupplierName) {
      queryParams.push(`supplierName=${chosenSupplierName}`)
    }

    if (month) {
      queryParams.push(`month=${month}`)
    }
    if (itemType) {
      queryParams.push(`itemType=${itemType}`)
    }

    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    setShowLoader(true);

    Fetch.get(`${url}${queryString}`)
    .then(data => {
      setBillArray(data)
      setBillArrayCopy(data)
      getDistinctSupplierNames(data)
      setShowLoader(false);

    })
    .catch(e => {
      // console.log('Error fetching suppliers bill')
    })
  }

  const getDistinctSupplierNames = (data) => {
    if (data) {
      const nicenames = data.map(bill => {
        return bill.nicename;
      })
      let nicenameSet = new Set(nicenames);
      setNicenames([...nicenameSet])
    }
  }
  
  useEffect(() => {
    getSuppliersBill()
  },[month, itemType])

  const getTotal = () => {
    let total = 0;
    billArrayCopy.forEach(bill => {
      total += bill.total;
    })
    return total;
  }

  const handleClick = (e) => {
    const action = e.target.dataset.action
    const itemId = e.target.dataset.itemId;
    switch (action) {
      case 'details':
        setItemId(itemId);
        setShowDetails(true);
        setShowSummary(false);    
        break;
      case 'pay':
        setItemId(itemId);
        setShowPaidModal(true);
      default:
        break;
    }
    
  }

  const handleBackButtonClick = () => {
    setShowDetails(false);
    setShowSummary(true);
  }

  const handlePaidModalConfirm = () => {

    setShowPaidModal(false);
  }

  const handlePaidModalClose = () => {
    setShowPaidModal(false);
  }

  const handleOnChange =(e) => {
    setChosenSupplierName(e.target.value)
    const filteredBillArray = billArray.filter(bill => {
      return bill.nicename === e.target.value;
    })
    if (filteredBillArray.length === 0) {
      setBillArrayCopy(billArray);
      return;
    }
    setBillArrayCopy(filteredBillArray);
  }

  const handleOnMonthChange = (e) => {
    setMonth(e.target.value);
  }

  const handleOnItemTypeChange = (e) => {
    setItemType(e.target.value);
  }

  const handleOnClearAllFilter = (e) => {
    setMonth('');
    setItemType('');
    setChosenSupplierName('');
  }
  

  const labelsField = (labelsText) => {
    const labelsArray = labelsText.split(',');
    return (
      labelsArray ? 
      labelsArray.map(label => {
        return (
          <span className="mr-2">
            <Badge pill variant="secondary">{label}</Badge>
          </span>
        )
      }) : <></>
    )
    
  }

  return (
    <Layout pageInfo={{ pageName: "reportsAdminSuppliersBill" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Suppliers Bill page" />
      {
        showSummary &&
          <Container fluid>
            <Row>
              <Col className="text-center mb-2">
                <h5>Suppliers Bill</h5>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form.Group as={Row} controlId="formGridState">
                  <Form.Label column sm={4}>Supplier Name</Form.Label>
                  <Col sm={8}>
                    <Form.Control as="select" value={chosenSupplierName} onChange={handleOnChange}>
                      <option>All Suppliers</option>
                      {
                        niceNames.map(x => {
                          return (
                            <option key={x} >{x}</option>
                          )
                        })
                      }
                    </Form.Control>
                  </Col>
                  
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} controlId="formGridState">
                  <Form.Label column sm={4}>Month</Form.Label>
                  <Col sm={8}>
                    <Form.Control as="select" value={month} onChange={handleOnMonthChange}>
                      <option value=''>--Select Month--</option>
                      {
                        months.map(x => {
                          return (
                            <option key={x} >{x}</option>
                          )
                        })
                      }
                    </Form.Control>
                  </Col>
                  
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} controlId="formGridState">
                  <Form.Label column sm={4}>Item Type</Form.Label>
                  <Col sm={8}>
                    <Form.Control as="select" value={itemType} onChange={handleOnItemTypeChange}>
                      <option value=''>--Select Item Types--</option>
                      {
                        itemTypes.map(x => {
                          return (
                            <option key={x} >{x}</option>
                          )
                        })
                      }
                    </Form.Control>
                  </Col>
                  
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="text-left" >
                <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a></small>
              </Col>
            </Row>
            <Row >
              <Col>
                <Table responsive className="text-center">
                  <thead>
                    <tr>
                      <th>Supplier Name</th>
                      <th>Cust ID</th>
                      <th>Item Type</th>
                      <th>Fabric ID/Desc/Lin</th>
                      <th>Details</th>
                      <th className="text-right">Total Item Cost</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    { billArrayCopy  && Array.isArray(billArrayCopy) ? 
                      billArrayCopy.map(item => {
                        return (
                          <tr key={item.itemId}>
                            <td>{item.nicename}</td>
                            <td>{item.custId}</td>
                            <td>{item.itemType}</td>
                            <td>{item.itemDesc}</td>
                            <td>{labelsField(item.labels)}</td>
                            <td className="text-right">${item.total}</td>
                            <td>
                              <Button
                                variant="info"
                                data-item-id={item.itemId}
                                data-action='details'
                                onClick={handleClick}
                                className="mr-2"
                              >
                                See Details
                              </Button>
                              <Button
                                data-item-id={item.itemId}
                                data-action='pay'
                                onClick={handleClick}
                                className="mr-2"
                              >
                                <span className="oi oi-check">&nbsp;</span>
                                Set as Paid
                              </Button>
                              <Button
                                variant='outline-danger'
                                data-item-id={item.itemId}
                                data-action='dispute'
                                onClick={handleClick}
                              >
                                <span className="oi oi-check">&nbsp;</span>
                                Dispute Bill
                              </Button>
                            </td>
                          </tr>
                        )
                      }) : ''
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className='text-right text-bold'>Total: </td>
                      <td className="text-right text-bold">${getTotal()}</td>
                      <td></td>
                    </tr>
                  </tfoot>
                </Table>  
              </Col>
            </Row>
          </Container>      
      }
      <OrderItemBill 
        show={showDetails} 
        itemId={itemId}
        handleBackButtonClick={handleBackButtonClick}
      >

      </OrderItemBill>
      <StandardModal 
          onConfirm={handlePaidModalConfirm}
          onClose={handlePaidModalClose} 
          show={showPaidModal}
          title="Set As paid"
          body={`Are you sure you want to set this item as PAID?`}
        >
        </StandardModal>
          
    </Layout>
  )
}

export default ReportAdminSuppliersBill