import React, {useState, useEffect} from 'react';
import Layout from '../../layout'
import Loader from '../../loading';

import { Container, Row, Col, Table, Tabs, Tab, Breadcrumb } from 'react-bootstrap'
import { navigate } from 'gatsby'
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import {DDMMYYYYFormat} from '../../../helpers/dateTime';
import GenericPagination from '../../../components/genericPagination';


const RPointsTransactions = ({location}) => {
  const [bonusTransactions, setBonusTransactions] = useState([]);
  const [showLoader, setShowLoader] = useState(false)
  const [key, setKey] = useState('all');
  const [page, setPage] = useState(1);
  const [rowLimit, setRowLimit] = useState(25);
  const [pagination, setPagination] = useState({});
  const [consultantBalance, setConsultantBalance] = useState(0)
  const [consultantUsedPoints, setConsultantUsedPoints] = useState(0)

  const getBonusTransactions = () => {
    setShowLoader(true);
    let queryString = '';
    let queryParams = [];
    const url=`${constants.BACKEND_URL}/points-trx/consultant`
    if (page) {
      queryParams.push(`page=${page}`)
    }

    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    Fetch.get(`${url}${queryString}`)
    .then(res => {
      if (res.error) {
        alert(res.error)
      }
      setPagination(res.pagination);
      setBonusTransactions(res.data)
      setShowLoader(false);
    })
    
  }

  const getConsultantBonusBalance = () => {
    const url=`${constants.BACKEND_URL}/points-trx/consultant/bonus/balance`
    Fetch.get(url).then(res => {
      if(!res) return;
      if (res && res.data && res.data.balance) setConsultantBalance(res.data.balance)
    })
  }
  const getConsultantUsedBalance = () => {
    const url=`${constants.BACKEND_URL}/points-trx/consultant/bonus/used`
    Fetch.get(url).then(res => {
      if(!res) return;
      if (res && res.data && res.data.balance) setConsultantUsedPoints(res.data.balance)
    })
  }

  useEffect(() => {
    getBonusTransactions();
    getConsultantBonusBalance();
    getConsultantUsedBalance();
    return () => {
    }
  }, [page])

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const tableBodyUsedPoints = () => {
    let tempId = 0;
    let whiteColor = true;
    return bonusTransactions.filter(t => t.amount < 0).map((trx, index) => {
      if (index !== 0) {
        if (tempId !== trx.orderId) whiteColor = !whiteColor;
      }
      const rowBg = whiteColor ? 'odd' : 'even';
      const result = (
        <tr key={trx.id} className={`${rowBg} ${Number(trx.amount) < 0 ? 'text-danger': ''}`}>
          <td className="text-center">{trx.customerId}</td>
          <td className="text-center">{trx.orderId}</td>
          <td className="text-center">{DDMMYYYYFormat(trx.time)}</td>
          <td className="text-center">{trx.type.toUpperCase()}</td>
          <td className="text-right">${Number(trx.priceAfterBonusOffset).toFixed(2)}</td>
          <td className="text-right">{trx.percentage}%</td>
          <td className="text-center">{Number(trx.amount) < 0 ? <span className="text-danger">(${Number(trx.amount * -1).toFixed(2)})</span> : <span className="text-success">${Number(trx.amount).toFixed(2)}</span>}</td>
        </tr>
      )
      tempId = trx.orderId;
      return result;
      
    })
  }
  const tableBodyBonusAll= () => {
    let tempId = 0;
    let whiteColor = true;
    return bonusTransactions.map((trx, index) => {
      if (index !== 0) {
        if (tempId !== trx.orderId) whiteColor = !whiteColor;
      }
      const rowBg = whiteColor ? 'odd' : 'even';
      const result = (
        <tr key={trx.id} className={`${rowBg} ${Number(trx.amount) < 0 ? 'text-danger': ''}`}>
          <td className="text-center">{trx.customerId}</td>
          <td className="text-center">{trx.orderId}</td>
          <td className="text-center">{trx.itemId}</td>
          <td className="text-center">{DDMMYYYYFormat(trx.time)}</td>
          <td className="text-center">{trx.type.toUpperCase()}</td>
          <td className="text-right">${Number(trx.priceAfterBonusOffset).toFixed(2)}</td>
          <td className="text-right">{trx.percentage}%</td>
          <td className="text-center">{Number(trx.amount) < 0 ? <span className="text-danger">(${Number(trx.amount * -1).toFixed(2)})</span> : <span className="text-success">${Number(trx.amount).toFixed(2)}</span>}</td>
        </tr>
      )
      tempId = trx.orderId;
      return result;
      
    })
  }

  return (
    <Layout pageInfo={{ pageName: "rpoints" }}> 
      <Loader show={showLoader}></Loader>
      
      <Container>
        
        <Row>
          <Col className="text-center mb-2">
            <h5>Statement Of Account</h5>
            <h6><small>Showing Your Statement of Account</small></h6>
          </Col>
        </Row>
        <Row>
          <Col>Balance: ${consultantBalance}</Col>
          <Col>Used/ Withdrawn: ${consultantUsedPoints}</Col>
        </Row>
      
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="all" title="All">
            
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center">Cust ID</th>
                  <th className="text-center">Order ID</th>
                  <th className="text-center">Item ID</th>
                  <th className="text-center">Disbursed On</th>
                  <th className="text-center">Type</th>
                  <th className="text-right">Nett Price</th>
                  <th className="text-right">Percentage Used</th>
                  <th className="text-center">Bonus</th>
                </tr>
              </thead>
              <tbody>
                {bonusTransactions && Array.isArray(bonusTransactions) ? 
                  tableBodyBonusAll() : <></>
                }
              </tbody>
            </Table>
            {pagination ? 
              <GenericPagination 
                pagination={pagination} 
                rowLimit={rowLimit}
                handleOnPaginationClick={handleOnPaginationClick}
              ></GenericPagination> : <></> }
          </Tab>
          <Tab eventKey="used" title="Used" >
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Disbursed On</th>
                  <th>Type</th>
                  <th className="text-right">Nett Price</th>
                  <th className="text-right">Percentage Used</th>
                  <th className="text-center">Bonus</th>
                </tr>
              </thead>
              <tbody>
                {bonusTransactions && Array.isArray(bonusTransactions) ? 
                  tableBodyUsedPoints() : <></>
                  
                }
              </tbody>
            </Table>
            
           
          </Tab>
        </Tabs>

      </Container>
    </Layout>
  )
}

export default RPointsTransactions