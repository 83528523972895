const getQueryString = (location) => {
	console.log(location);
  if (location && location.search) {
    let queryArray = location.search.replace("?", "").split("&");
console.log(queryArray);
    const obj = {}
    queryArray.forEach((x) => {
      const keyValueArray = x.split("=");
      obj[keyValueArray[0]] = keyValueArray[1]
    })
console.log(obj);    
    return obj;
  }
  return {};
}

const changeSortDirection = (property, sortCopy) => {
  let index = null;
  for (let i = 0; i < sortCopy.length; i++) {
    const element = sortCopy[i];
    const field = element.split("_")[0];
    if (property === field) {
      index = i;
      break;
    }
  }

  if (index !== null) {
    sortCopy = sortCopy.map(element => {
      const field = element.split("_")[0];
      let oldDirection = element.split("_")[1];
      if (property === field) {
        const newDirection = oldDirection.toUpperCase() === 'ASC' ? 'DESC' : 'ASC'
        return `${property}_${newDirection}`;
      }
      return `${field}_${oldDirection}`
    });
  } else {
    sortCopy.push(`${property}_ASC`)
  }
  
  return sortCopy
}


export { getQueryString, changeSortDirection }