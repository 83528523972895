import React from 'react';
import { Form, Row, Col } from 'react-bootstrap'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerFilter = ({handleDatePickerChange, selected, datePickerLabel='Date Filter'}) => {
  const handleOnChange = (e) => {
    handleDatePickerChange(e)
  }

  return (
    <>
      <Form.Group as={Row} controlId="formDatePicker">
        <Form.Label column sm={4} lg={4} className='text-left'>
          {datePickerLabel}
        </Form.Label>
        <Col sm={8} lg={8}>
        <DatePicker
            className="reactDatePicker"
            dateFormat="dd/MM/yyyy"
            todayButton="Today"
            placeholderText="--Select Date--"
            selected={selected}
            isClearable
            onChange={handleOnChange}
            withPortal
          />
        </Col>
      </Form.Group>
    </>
  )
}

export default DatePickerFilter