import React from "react"
import { Router } from "@reach/router"
import BillReport from '../components/billReport'
import FabricOrder from '../components/reports/admin/fabricOrder'
import ItemsInProduction from '../components/reports/admin/ItemsInProduction'
import RefundedItems from '../components/reports/admin/refundedItems'
import ReportAdminSuppliersBill from '../components/reports/admin/suppliersBill'
import ConsultantOrders from '../components/reports/consultants/orders'
import ConsultantSalesReport from '../components/reports/consultants/sales'
import RPointsTransactions from '../components/reports/consultants/rpoints'
import Default from "../components/default"
import { withPrefix } from 'gatsby'

const Reports = () => {
  return (
    <Router basepath={`/reports`}>
      <FabricOrder path={withPrefix(`/admin/fabricOrder`)}/>
      <ItemsInProduction path={withPrefix(`/admin/ItemsInProduction`)}/>
      <RefundedItems path={withPrefix(`/admin/refundedItems`)}/>
      <ReportAdminSuppliersBill path={withPrefix(`/admin/supplierBill`)}/>
      <ConsultantOrders path={withPrefix(`/consultants/orders`)}/>
      <ConsultantSalesReport path={withPrefix(`/consultants/sales`)}/>
      <RPointsTransactions path={withPrefix(`/consultants/rpoints`)}/>
      <BillReport path={withPrefix(`/bill`)}/>
      <Default path={withPrefix(`/`)}/>
    </Router>
  )
}
export default Reports

