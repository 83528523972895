import React, {useState, useEffect, useRef} from "react"
import Layout from "./layout"
import {Container, Table, Row, Col, Form, FormControl, Badge, Button } from 'react-bootstrap'
import SEO from "./seo";
import Loader from './loading';
import StandardModal from './modal';
import GenericPagination from './genericPagination';
import DatePickerFilter from '../components/datepickerFilter';

import constants from '../helpers/constants';
import Fetch from "../helpers/fetch";
import Select from 'react-select'

import { DDMMYYYYFormat, YYYYMMDDFormat, generateMonths } from '../helpers/dateTime';
import { isAdmin, isAmir, isIsabelle } from '../helpers/roles'


const BillReport = ({location}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [bills, setBills] = useState([]);
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);

  const [custId, setCustId] = useState(location && location.state && location.state.custId ? location.state.custId : '');
  const [itemId, setItemId] = useState('');
  
  const [itemType, setItemType] = useState('')
  const [logType, setLogType] = useState('')
  const [page, setPage] = useState(1);
  const [modalInfo, setModalInfo] = useState({})
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [orderItem, setOrderItem] = useState({});

  const [startDate, setStartDate] = useState(location && location.state && location.state.startDate ? location.state.startDate : '');
  const [endDate, setEndDate] = useState(location && location.state && location.state.endDate ? location.state.endDate : new Date())
  const [billStatusFilter, setBillStatusFilter] = useState(location && location.state && location.state.billStatusFilter ? location.state.billStatusFilter : '')

  const [statusId, setStatusId] = useState('');
  const [billStatus, setBillStatus] = useState('')
  const [showBillStatusModal, setShowBillStatusModal] = useState(false)
  const [itemTypeFilter, setItemTypeFilter] = useState('');

  const [logTypes, setLogTypes] = useState(['Processing', 'Altering'])
  const selectedItemTypes = useRef('');


  const getBills = () => {
    let queryString = '';
    let queryParams = [];
    let url=`${constants.BACKEND_URL}/status-trx`
    if (custId) {
      queryParams.push(`custId=${custId}`)
    }
    if (itemId) {
      queryParams.push(`itemId=${itemId}`)
    }
    if (itemTypeFilter) {
      queryParams.push(`itemTypeFilter=${itemTypeFilter}`)
    }
    if (logType) {
      queryParams.push(`logType=${logType}`)
    }
    if (billStatusFilter) {
      queryParams.push(`billStatusFilter=${billStatusFilter}`)
    }
    if (startDate) {
      queryParams.push(`startDate=${YYYYMMDDFormat(startDate)}`)
    }
    if (endDate) {
      queryParams.push(`endDate=${YYYYMMDDFormat(endDate)}`)
    }
    if (page) {
      queryParams.push(`page=${page}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    setShowLoader(true)
    Fetch.get(`${url}${queryString}`)
    .then((data) => {
      setBills(data.data)
      setPagination(data.pagination)
      setShowLoader(false)
    })
  }

  useEffect(() => {
    if (custId || itemId ) {
      const timeOutId = setTimeout(() => getBills(), constants.SEARCH_TIMEOUT_IN_MS);
      return () => clearTimeout(timeOutId);
    } else {
      getBills()
    }
    return () => {
    }
  }, [page, custId, itemId, itemTypeFilter, logType, startDate, endDate, billStatusFilter])

  const handleBillStatusChange = (e) => {
    setShowBillStatusModal(true);
    const {statusId, itemId, orderId, custId} = e.target.dataset
    setStatusId(statusId);
    setModalInfo({
      itemId,
      orderId,
      custId
    })
    setBillStatus(e.target.value)
  }

  const handleBillStatusConfirm = (e) => {
    const url=`${constants.BACKEND_URL}/status-trx/${statusId}`
    const data={
      billStatus
    }
    Fetch.put(url, JSON.stringify(data)).then(data => {
     getBills()
    })
    setShowBillStatusModal(false);
  }

  const handleOnSearchCustId = (e) => {
    setCustId(e.target.value);
    setPage(1);
  }

  const handleOnSearchItemId = (e) => {
    setItemId(e.target.value);
    setPage(1);
  }
  
  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const handleOnClearAllFilter = (e) => {
    setPage(1);
    setCustId('')
    if (location && location.state ) location.state.custId = '';
    setItemId('');
    setStartDate('')
    setEndDate('')
    selectedItemTypes.current =''
    setItemTypeFilter('')
    setLogType('');
    setBillStatusFilter('')
  }

  const handleOnFilterPillClick = (e) => {
    const filter = e.target.dataset.filter
    switch (filter) {
      case 'custId':
        setCustId('')
        location.state.custId = ''
        break;
      case 'itemId':
        setItemId('')
        break;
      case 'startDate':
        setStartDate('')
        break;
      case 'endDate':
        setEndDate('')
        break;
      case 'itemType':
        selectedItemTypes.current =''
        setItemTypeFilter('')
        break;
      case 'logType':
        setLogType('')
        break;
      case 'billStatusFilter':
        setBillStatusFilter('')
        break;
      default:
        break;
    }
  }
  
  const handleOnLogTypeChange = (e) => {
    setLogType(e.target.value)
  }

  const handleOnBillStatusFilterChange = (e) => {
    setBillStatusFilter(e.target.value)
  }

  const groupedOptions = (params) => {
    return constants.ITEM_TYPES_GROUPED_OPTIONS;
  }

  const defaultValue = () => {
    let options2 = [];
    if (selectedItemTypes.current) {
      const options = selectedItemTypes.current.split(",");
      options.forEach((item) => {
        options2.push({
          value: item,
          label: item,
        })
      })
    }
    return options2;
  }

  const MultiSelect = () => (
    <Select 
      placeholder="--Select Item Type--"
      defaultValue={defaultValue()}
      isMulti 
      closeMenuOnSelect={false} 
      options={groupedOptions()}
      onChange={handleOnMultiSelectChange}
      onBlur={handleOnBlur}
    />
  )

  const handleOnMultiSelectChange = (values) => {
    if (!values) {
      selectedItemTypes.current ='';
      setItemTypeFilter('');
      return;
    }
    let itemTypesCopy =[];
    let valuesCopy = [...values];
    if (Array.isArray(valuesCopy) && valuesCopy.length > 0) {
      valuesCopy.forEach(value => {
        itemTypesCopy.push(value.value)
      })
    }
    selectedItemTypes.current = itemTypesCopy.join(",");
  }

  const handleOnBlur = () => {
    setItemTypeFilter(selectedItemTypes.current);
    setPage(1);
  }

  const handleStartDatePickerChange = (e) => {
    setStartDate(e)
  }

  const handleEndDatePickerChange = (e) => {
    setEndDate(e)
  }
  
  const handleOnDownloadClick = (e) => {
    // const {itemId, itemType, itemDesc} = e.target.dataset;
    setShowLoader(true)
    // downloadBillReport(itemId, itemType, itemDesc)
    downloadBillReport()
  }

  const downloadBillReport = () => {
    let queryString = '';
    let queryParams = [];
    let url=`${constants.BACKEND_URL}/status-trx/download`
    if (custId) {
      queryParams.push(`custId=${custId}`)
    }
    if (itemId) {
      queryParams.push(`itemId=${itemId}`)
    }
    if (itemTypeFilter) {
      queryParams.push(`itemTypeFilter=${itemTypeFilter}`)
    }
    if (logType) {
      queryParams.push(`logType=${logType}`)
    }
    if (billStatusFilter) {
      queryParams.push(`billStatusFilter=${billStatusFilter}`)
    }
    if (startDate) {
      queryParams.push(`startDate=${YYYYMMDDFormat(startDate)}`)
    }
    if (endDate) {
      queryParams.push(`endDate=${YYYYMMDDFormat(endDate)}`)
    }
    if (page) {
      queryParams.push(`page=${page}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    setShowLoader(true)
    Fetch.get(`${url}${queryString}`)
      .then(async (res) => {
        const reader = res.getReader();
        return new ReadableStream({
          async start(controller) {
            while (true) {
              const { done, value } = await reader.read();
              // When no more data needs to be consumed, break the reading
              if (done) {
                break;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
            }
            // Close the stream
            controller.close();
            reader.releaseLock();
          }
        })
      })
      // Create a new response out of the stream
      .then(rs => new Response(rs))
      // Create an object URL for the response
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      // Update image
      .then(url => {
        const fileName = `BillReport.pdf`
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent('click'));
        setShowLoader(false);
      })
      .catch(e => {
        setShowLoader(false);
        console.error(e);
      });
  }

  const tableBody = () => {
    let tempId = 0;
    let whiteColor = true;
    return bills.map((item, index) => {
      if (index !== 0) {
        if (tempId !== item.custId) whiteColor = !whiteColor;
      }
      const rowBg = whiteColor ? 'odd' : 'even';
      const result = (          
        <tr key={item.id} className={item.billStatus !== 'Active' ? 'table-row-muted' : rowBg}>
          <td>{item.custId}</td>
          <td>{item.orderId}</td>
          <td>{item.itemId}</td>
          <td>{item.itemDesc}</td>
          <td>{item.itemType}</td>
          <td>{item.prevStatus}</td>
          <td>{DDMMYYYYFormat(item.currentStatusTime)}</td>
          <td>
            {
              item.billStatus === 'Active' && (isAdmin() || isIsabelle()) && !isAmir() ? (
                <Form.Control 
                  as="select" 
                  data-status-id={item.id}
                  data-item-id={item.itemId}
                  data-order-id={item.orderId}
                  data-cust-id={item.custId}
                  value={item.billStatus} 
                  onChange={handleBillStatusChange}
                >
                  <option>Active</option>
                  <option>Paid</option>
                  <option>Waived</option>
                  <option>Void</option>
                </Form.Control>
              ) : <> {item.billStatus}</>
            }
          </td>
          <td>{remarksField(item)}</td>
        </tr>
      )
      tempId = item.custId;
      return result;
    })
  }

  const remarksField = (item) => {
    return (
      <>
        {
          item.remarks && item.remarks.replace(/\s/g,'').length > 0 ? (
            <div className="pointer text-wrap" style={{"whiteSpace": "pre-line"}} data-id={item.id} onClick={handleOnEditRemarksClick}>
              {item.remarks.split ('\n').map (
                (line, i) => {
                  return <div data-id={item.id} key={i}>{line}</div> 
                }
              )}
            </div>
          ) : (
            <div ><span className="pointer oi oi-pencil" data-id={item.id} onClick={handleOnEditRemarksClick}></span></div>
          )
        }
      </>
    )
  }

  const handleOnRemarksChange = (e) => {
    const obj = {
      remarks: e.target.value 
    }
    setOrderItem({...orderItem, ...obj});
  }

  const handleOnEditRemarksClick = (e) => {
    setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
    setShowRemarksModal(true)
  }

  const getOrderItemFromProdArray = (orderItemId) => {
    if (bills.length > 0) {
      const orderItem = bills.filter(item => {
        return item.id == Number(orderItemId)
      })
      return orderItem[0];
    }
  }

  const handleRemarksClose = () => {
    setShowRemarksModal(false);
  }

  const handleRemarksConfirm = () => {
    // Patch the remarks
    if (orderItem.remarks || orderItem.remarks === '') {
      const data = {
        remarks: orderItem.remarks
      }
      const url = `${constants.BACKEND_URL}/status-trx/${orderItem.id}/remarks`;
      Fetch.patch(url, JSON.stringify(data))
      .then(data => {
        getBills()
        setShowRemarksModal(false);
      })
    }
  }

  return (
    <Layout pageInfo={{ pageName: "Supplier Bill Report" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Supplier Bill Report page" />
      <Container fluid >
        <Row>
          <Col className="text-center mb-2">
            <h5>Supplier Bill Report</h5>
            <h6><small>- Showing Bills -</small></h6>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={2}>
            <Form.Group as={Row} className="mr-2 mb-2">
              <Form.Label column sm={3}>Cust ID</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={custId}               
                  onChange={handleOnSearchCustId}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={2}>
            <Form.Group as={Row} className="mr-2 mb-2">
              <Form.Label column sm={3}>Item ID</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={itemId}               
                  onChange={handleOnSearchItemId}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm={6} md={4} lg={2}> 
            <DatePickerFilter 
              datePickerLabel= {'Start Date'} 
              selected={startDate}
              handleDatePickerChange={handleStartDatePickerChange}
             >
            </DatePickerFilter>
          </Col>
          <Col sm={6} md={4} lg={2}>
            <DatePickerFilter 
              datePickerLabel= {'End Date'} 
              selected={endDate}
              handleDatePickerChange={handleEndDatePickerChange}
            >
            </DatePickerFilter>
          </Col>

          {/* <Col sm={6}>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>Item In</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" value={monthIn} data-month-type='itemIn' onChange={handleOnMonthChange}>
                  <option value=''>--Select Month--</option>
                  {
                    months.map(x => {
                      return (
                        <option key={x} >{x}</option>
                      )
                    })
                  }
                </Form.Control>
              </Col>
            </Form.Group>
          </Col> */}

          <Col sm={6} md={4} lg={2}>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>Item Type</Form.Label>
              <Col>
                <MultiSelect className="text-left"></MultiSelect>
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>Bill Log</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" value={logType} onChange={handleOnLogTypeChange}>
                  <option value=''>--Select Log Type--</option>
                  {
                    logTypes.map(x => {
                      return (
                        <option key={x} >{x}</option>
                      )
                    })
                  }
                </Form.Control>
              </Col>
              
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4} lg={2}>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>Bill Status</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" value={billStatusFilter} onChange={handleOnBillStatusFilterChange}>
                  <option value=''>--Select Bill Status --</option>
                  <option value='active'>Active</option>
                  <option value='paid'>Paid</option>
                  <option value='waived'>Waived</option>
                  <option value='void'>Void</option>
                </Form.Control>
              </Col>
              
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a> </small>
            <span onClick={handleOnFilterPillClick}>
              {custId && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Cust ID: {custId} 
                  <span className="oi oi-circle-x ml-2" data-filter="custId" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {itemId && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Item ID: {itemId} 
                  <span className="oi oi-circle-x ml-2" data-filter="itemId" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {startDate && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Start Date: {DDMMYYYYFormat(startDate)}  
                  <span className="oi oi-circle-x ml-2" data-filter="startDate"  style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {endDate && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  End Date: {DDMMYYYYFormat(endDate)}  
                  <span className="oi oi-circle-x ml-2" data-filter="endDate"  style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {selectedItemTypes.current && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Selected Types: {selectedItemTypes.current} 
                  <span className="oi oi-circle-x ml-2" data-filter="itemType" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {logType && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Log Type: {logType} 
                  <span className="oi oi-circle-x ml-2" data-filter="logType" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {billStatusFilter && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Bill Status: {billStatusFilter} 
                  <span className="oi oi-circle-x ml-2" data-filter="billStatusFilter" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
            </span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="float-right">
            <Button
              onClick={handleOnDownloadClick}
            >
             <span className="oi oi-print mr-2"></span>Download report
            </Button>
          </Col>
        </Row>
        <Table responsive>
          <thead>
            <tr>
              <th>Cust ID</th>
              <th>Order ID</th>
              <th>Item ID</th>
              <th>FabID/Desc/Cont/Lin</th>
              <th>Item Type</th>
              <th>Item Log</th>
              <th>Item In Date</th>
              <th>Bill Status</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {
              bills && Array.isArray(bills) ? 
              tableBody() : <tr></tr>
            }
          </tbody>
        </Table>
        {pagination ? 
          <GenericPagination 
            pagination={pagination} 
            rowLimit={rowLimit}
            handleOnPaginationClick={handleOnPaginationClick}
          ></GenericPagination> : <></>}
      </Container>
      <StandardModal 
        onConfirm={handleBillStatusConfirm}
        onClose={() => setShowBillStatusModal(false)} 
        show={showBillStatusModal}
        title="Editing BillStatus"
        body={``}
      >
        <div>Customer Id: <strong>{modalInfo.custId}</strong></div>
        <div>Order Id: <strong>{modalInfo.orderId}</strong></div>
        <div>Item Id: <strong>{modalInfo.itemId}</strong></div>
        <div>Are you sure you want to change bill status to <strong>{billStatus}</strong>?</div>
      </StandardModal>
      <StandardModal 
        onConfirm={handleRemarksConfirm}
        onClose={handleRemarksClose} 
        show={showRemarksModal}
        title="Editing Remarks"
        body={``}
      >
        <Form.Group >
          <Form.Control as="textarea" rows="3" data-id={orderItem.itemId} onChange={handleOnRemarksChange} value={orderItem.remarks}>
          </Form.Control>
        </Form.Group>
      </StandardModal>

    </Layout>
  )
}

export default BillReport