import React, {useState, useEffect} from 'react';
import {Container, Table, Button, Row, Col} from 'react-bootstrap'
import Fetch from '../helpers/fetch';
import constants from '../helpers/constants';

const OrderItemBill = ({show, itemId, handleBackButtonClick}) => {
  const [billArray, setBillArray] = useState([])
  
  const getSuppliersBill = () => {
    Fetch.get(`${constants.BACKEND_URL}/suppliers-bill/${itemId}`)
    .then(data => {
      setBillArray(data)
    })
    .catch(e => {
    })
  }

  const getTotal = () => {
    let total = 0;
    billArray.forEach(bill => {
      total += bill.cost;
    })
    return total;
  }
  
  useEffect(() => {
    getSuppliersBill()
  },[itemId])

  return ( show &&
    <Container className="text-center" >
      <Row>
        <Col className="text-left mb-2">
          <Button onClick={handleBackButtonClick}><span className="oi oi-chevron-left"></span> Back</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>Supplier Name</th>
                <th>Label</th>
                <th className="text-right">Cost</th>
              </tr>
            </thead>
            <tbody>
              {billArray && Array.isArray(billArray)  ? 
                billArray.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{item.nicename}</td>
                      <td>{item.label}</td>
                      <td className="text-right">${item.cost}</td>
                    </tr>
                  )
                }) : ''
              }
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td className='text-right text-bold'>Total: </td>
                <td className="text-right text-bold">${getTotal()}</td>
              </tr>
            </tfoot>
          </Table>  
        </Col>
      </Row>
    </Container>
  )
}

export default OrderItemBill