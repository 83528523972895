import React, {useState, useEffect, useRef } from "react"
import { navigate } from '@reach/router';

import {Table, Form, FormControl, Badge, Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap'
import Layout from "../../../components/layout"
import RightSidebar from "../../../components/sidebar/rightSideBar"
import SEO from "../../../components/seo";
import LocalStorage from "../../../components/localStorage";
import GenericPagination from '../../../components/genericPagination';
import Loader from '../../../components/loading/';
import GroupingFilter from '../../../components/filters/groupingFilter'
import SortArrows from '../../../components/sortArrows';
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import {replaceSpaceInPhoneNumber} from '../../../helpers/string';
import { DOSDDMMYYYYFormat, DDMMYYYYFormat, generateMonths } from '../../../helpers/dateTime';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import JwtDecode, * as jwt from 'jwt-decode'
import { getQueryString, changeSortDirection } from '../../../helpers/queryString'

const ConsultantOrders = ({location}) => {
  const decodedToken = LocalStorage.get('jwt') ? JwtDecode(LocalStorage.get('jwt')) : {};
  const isAdmin = decodedToken && decodedToken.role && decodedToken.role === 'admin' ? true : false;
  const isConsultant = decodedToken && decodedToken.role && decodedToken.role === 'consultant' ? true : false;
  const isManager = decodedToken && decodedToken.role && decodedToken.role === 'manager' ? true : false;
  
  const [dataTableArray, setDataTableArray] = useState([]);
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [custId, setCustId] = useState('');
  const [orderId, setOrderId] = useState('')
  const [phone, setPhone] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [sort, setSort] = useState([])
  
  const [consultants, setConsultants] = useState([]);
  const [consultantName, setConsultantName] = useState(isConsultant ? decodedToken.username : '');
  const [months, setMonths] = useState(generateMonths(6,6));
  const [urgentDateMonth, setUrgentDateMonth] = useState('')
  const [page, setPage] = useState(1);
  const [orderItem, setOrderItem] = useState({});
  const [itemType, setItemType] = useState('');
  const [itemTypeFilter, setItemTypeFilter] = useState('');
  const [chosenStatus, setChosenStatus] = useState('');
  const [statusArray, setStatusArray] = useState(constants.COMMON_STATUS_ARRAY);
  const [showRightSidebar, setshowRightSidebar] = useState(false)
  const selectedItemTypes = useRef('');

  const [orderGroupings, setOrderGroupings] = useState([]);
  const [orderGroupingFilter, setOrderGroupingFilter] = useState('');

  useEffect(() => {
    getGroupings()
    getConsultants()
    
  },[]);

  useEffect(() => {
    const queryString = getQueryString(location);
    const sorts = Object.keys(queryString).length > 0 ? queryString['sort'].split(",") : [];
    setSort(sorts)
  },[location])

  useEffect(() => {
    if (custId || phone ) {
      const timeOutId = setTimeout(() => getOrderItemsByConsultantName('timeout'), constants.SEARCH_TIMEOUT_IN_MS);
      return () => clearTimeout(timeOutId);
    } else {
      getOrderItemsByConsultantName()
    }

  },[custId, phone, consultantName, page, itemTypeFilter, sort, chosenStatus, urgentDateMonth, orderGroupingFilter])

  const getGroupings = (params) => {
    Fetch.get(`${constants.BACKEND_URL}/order-groupings`)
    .then(res => {

      if(res.error) {
        // setShowToast(true);
        // seterrorMessage(res.error);
        return;
      }
      setOrderGroupings(res.data);
    })
  }

  const getConsultants = () => {
    let url = '';
    if (isConsultant) {
      url = `${constants.BACKEND_URL}/consultants/${decodedToken.username}`
    } else {
      url = `${constants.BACKEND_URL}/consultants`
    }

    Fetch.get(url)
      .then(data => {
        setConsultants(data)
      })
  }

  const getOrderItemsByConsultantName = () => {
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/order-items/consultants`
    if (orderId) {
      queryParams.push(`orderId=${orderId}`)
    }
    if (custId) {
      queryParams.push(`custId=${custId}`)
    }
    if (phone) {
      queryParams.push(`phone=${phone}`)
    }
    if (page) {
      queryParams.push(`page=${page}`)
    }
    if (consultantName) {
      queryParams.push(`name=${consultantName}`)
    }
    if (orderGroupingFilter) {
      queryParams.push(`tag=${orderGroupingFilter}`)
    }
    if (itemTypeFilter) {
      queryParams.push(`itemTypeFilter=${itemTypeFilter}`)
    }
    if (urgentDateMonth) {
      queryParams.push(`month=${urgentDateMonth}`)
    }
    if (chosenStatus) {
      queryParams.push(`status=${chosenStatus}`)
    }
    if (sort) {
      queryParams.push(`sort=${sort}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    setShowLoader(true);
    Fetch.get(`${url}${queryString}`)
      .then(data => {
        setDataTableArray(data.data)
        setPagination(data.pagination)
        setShowLoader(false);
        setshowRightSidebar(false);
      })
    
  }

  const searchCustId = (e) => {
    setCustId(e.target.value);
    setPage(1);
  }

  const searchOrderId = (e) => {
    setOrderId(e.target.value);
    setPage(1);
  }

  const searchPhone = (e) => {
    const sanitizedPhone = replaceSpaceInPhoneNumber(e.target.value)
    setPhone(sanitizedPhone);
    setPage(1);
  }

  const handleOnConsultantChange = (e) => {
    setConsultantName(e.target.value);
    setPage(1);
  }

  const handleOnGroupingChange = (e) => {
    setOrderGroupingFilter(e.target.value);
    setPage(1);
  }

  const handleOnUrgentDateMonthChange = (e) => {
    setUrgentDateMonth(e.target.value);
    setPage(1);
  }

  const handleOnStatusChange = (e) => {
    setChosenStatus(e.target.value);
    setPage(1);
  }
  
  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const handleOnClearAllFilter = (e) => {
    if (isAdmin || isManager) setConsultantName('');
    setItemType('');
    setChosenStatus('');
    setCustId('')
    setPhone('')
    setItemTypeFilter('');
    selectedItemTypes.current ='';
    setUrgentDateMonth('');
    setOrderGroupingFilter('')
    setPage(1);
  }

  const getItemInByFromObject = (itemInByString) => {
    const itemInByObject = itemInByString ? JSON.parse(itemInByString) : null
    if (itemInByObject) {
      const itemInBy = itemInByObject.changedDate ? 
      new Date(itemInByObject.changedDate) : 
      new Date(itemInByObject.original);

      return itemInBy;
    }
  }

  const max120Field = (item) => {
    const max120 = new Date().getTime() - new Date(item.time).getTime()
    const Difference_In_Days = Math.round(max120 / (1000 * 3600 * 24))

    return Difference_In_Days ? Difference_In_Days : '--';
  }

  const urgentDateField = (item) => {
    let urgentDate = item.urgent_date;
    if (!urgentDate || urgentDate === '0000-00-00') {
      return;
    }
    urgentDate = new Date(urgentDate.split('T')[0]).toLocaleString("en-SG").split(",")[0];
    return (
      <>
        {urgentDate}
      </>
    )
  }

  const itemInByDateField = (item) => {
    if (item.item_in_by && item.item_in_by.indexOf("1970") > -1 ) {
      item.item_in_by = null;
    }
    const itemInByObject = item.item_in_by ? JSON.parse(item.item_in_by) : null
    
    if (itemInByObject) {
      const itemInBy = itemInByObject.changedDate ? 
      new Date(itemInByObject.changedDate) : 
      new Date(itemInByObject.original);
      
      return (
        <>
          {DDMMYYYYFormat(itemInBy)}
        </>
      )
      // let fwdBuffer = 0;
      // if (addDays(itemInByObject.original, itemInByObject.forwardBuffer) < new Date(item.urgent_date)) {
      //   fwdBuffer = itemInByObject.forwardBuffer
      // }
      // return (
      //   itemInBy && item.status !== 'Collected' ?  (
      //     <>
      //       <div 
      //         className="pointer"
      //         data-id={item.id} 
      //         onClick={handleItemInByDateEditClick}>
      //         {itemInBy} 
      //       </div>
      //       <div>
      //         <Form noValidate className='editUrgentDate d-none' id={`form${item.id}`} data-id={item.id}>
      //           <Form.Group as={Row} controlId="" className="mt-2">
      //             <Col>
      //               <DatePicker
      //                 className="reactDatePicker"
      //                 dateFormat="dd/MM/yyyy"
      //                 todayButton="Today"
      //                 selected={itemInByDate}
      //                 minDate={substractDays(itemInByObject.original, itemInByObject.backwardBuffer)}
      //                 maxDate={addDays(itemInByObject.original, fwdBuffer)}
      //                 placeholderText="Select New Item In By Date"
      //                 onChange={handleOnDateFilterChange}
      //                 withPortal
      //               />
      //             </Col>
      //           </Form.Group>
      //           <Button className='mr-2' data-action="save" data-id={item.id} type="submit" onClick={handleSubmit}>Save</Button>
      //           <Button variant="secondary" data-action='cancel' data-id={item.id} type="submit" onClick={handleSubmit}>Cancel</Button>
      //         </Form>
      //       </div>
            
      //     </>
      //     ) : <></>
        
      // )

    }
  }
  

  const groupedOptions = (params) => {
    return constants.ITEM_TYPES_GROUPED_OPTIONS;
  }

  const defaultValue = () => {
    let options2 = [];
    if (selectedItemTypes.current) {
      const options = selectedItemTypes.current.split(",");
      options.forEach((item) => {
        options2.push({
          value: item,
          label: item,
        })
      })
    }
    return options2;
  }
  
  const MultiSelect = () => (
    <Select 
      placeholder="--Select Item Type--"
      defaultValue={defaultValue()}
      isMulti 
      closeMenuOnSelect={false} 
      options={groupedOptions()}
      onChange={handleOnMultiSelectChange}
      onBlur={handleOnBlur}
    />
  )
  const handleOnMultiSelectChange = (values) => {
    if (!values) {
      selectedItemTypes.current ='';
      setItemTypeFilter('');
      return;
    }
    let itemTypesCopy =[];
    let valuesCopy = [...values];
    if (Array.isArray(valuesCopy) && valuesCopy.length > 0) {
      valuesCopy.forEach(value => {
        itemTypesCopy.push(value.value)
      })
    }
    selectedItemTypes.current = itemTypesCopy.join(",");
  }

  const getOrderItemFromProdArray = (orderItemId) => {
    if (dataTableArray.length > 0) {
      const orderItem = dataTableArray.filter(item => {
        return item.id == Number(orderItemId)
      })
      return orderItem[0];
    }
  }

  const handleOnBlur = () => {
    setItemTypeFilter(selectedItemTypes.current);
    setPage(1);
  }

  const handleOnFilterPillClick = (e) => {
    const filter = e.target.dataset.filter
    switch (filter) {
      case 'consultantName':
        !isConsultant ? setConsultantName('') : setConsultantName(decodedToken.username);
        break;
      case 'selectedItemTypes.current':
        selectedItemTypes.current ='';
        setItemTypeFilter('');
        break;
      case 'urgentDateMonth':
        setUrgentDateMonth('');
        break;
      case 'chosenStatus':
        setChosenStatus('')
        break;
      case 'orderId':
        setOrderId('')
        break;
      case 'custId':
        setCustId('')
        break;
      case 'phone':
        setPhone('')
        break;
      case 'orderGroupingFilter':
        setOrderGroupingFilter('')
        break;
      case 'sort':
        setSort([])
        const cleanURLParams = location.search
          .replace(/(sort=.+&)/g, "")
          .replace(/(sort=.+)/g, "")
          .replace(/\?/g, "")
        navigate(`${location.origin}${location.pathname}${cleanURLParams}`)
        break;
      default:
        break;
    }
  }

  const toggleRightSidebar = (e) => {
    setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
    if (Number(e.target.dataset.custId) !== Number(orderItem.custid)) {
      setshowRightSidebar(!showRightSidebar)
      setshowRightSidebar(true);
    } else {
      setshowRightSidebar(!showRightSidebar)
    }
  }

  const handleOnSidebarClose = () => {
    setshowRightSidebar(false);
  }

  const handleOnSortClick = (e) => {
    const {property} = e.target.dataset;
    if (property) {
      let sortCopy = [...sort];
      sortCopy = changeSortDirection(property, sortCopy)
      const noDuplicateSort = [...new Set(sortCopy)];
      navigate(`?sort=${noDuplicateSort}`)
    }
  }

  return (
    <Layout pageInfo={{ pageName: "consultantOrders" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Consultant Orders" />
      <Row>
        <Col className="text-center mb-2">
          <h5>Consultant Orders</h5>
          <h6><small>- Showing Open Orders Only -</small></h6>
        </Col>
      </Row>
      <Row>
        {(isAdmin || isManager) && 
          <Col>
            <Form.Group as={Row}>
              {/* <Form.Label column sm={4}>Consultant name*</Form.Label> */}
              <Col>
                <Form.Control as="select" value={consultantName} onChange={handleOnConsultantChange}>
                  <option value=''>--Select Consultant--</option>
                  {
                    consultants && Array.isArray(consultants) ? consultants.map(x => {
                      return (
                        <option key={x.name} >{x.name}</option>
                      )
                    }) : <></>
                  }
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
        }
        
        <Col>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>Item Type</Form.Label>
            <Col>
              <MultiSelect className="text-left"></MultiSelect>
            </Col>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>Urgent Date</Form.Label>
            <Col>
              <Form.Control as="select" value={urgentDateMonth} onChange={handleOnUrgentDateMonthChange}>
                <option value=''>--Select Urgent Date (by Month)--</option>
                {
                  months.map(x => {
                    return (
                      <option key={x} >{x}</option>
                    )
                  })
                }
              </Form.Control>
            </Col>
            
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>Status</Form.Label>
            <Col>
              <Form.Control as="select" value={chosenStatus} onChange={handleOnStatusChange}>
                <option value=''>--Select Status--</option>
                {
                  statusArray.map(x => {
                    return (
                      <option key={x} >{x}</option>
                    )
                  })
                }
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="mr-2 mb-2">
            <Form.Label column sm={3}>Cust ID</Form.Label>
            <Col>
              <FormControl
                type="text"
                placeholder="Search..." 
                value={custId}               
                onChange={searchCustId}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} >
            <Form.Label  column sm={3}>Cust HP</Form.Label>
            <Col>
              <FormControl
                type="text"
                placeholder="Search..." 
                value={phone}               
                onChange={searchPhone}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <GroupingFilter
          show={true}
          orderGroupingFilter={orderGroupingFilter}
          handleOnGroupingChange={handleOnGroupingChange}
          orderGroupings={orderGroupings}
          withLabels={false}
        ></GroupingFilter>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
      </Row>
      
      <Row className="mb-3">
        <Col>
          <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a> </small>
          <span onClick={handleOnFilterPillClick}>
            {consultantName && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Consultant: {consultantName} 
                <span className="oi oi-circle-x ml-2" data-filter="consultantName" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {selectedItemTypes.current && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Selected Types: {selectedItemTypes.current} 
                <span className="oi oi-circle-x ml-2" data-filter="selectedItemTypes.current" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {urgentDateMonth &&
              <Badge pill variant="info" className="ml-1 mr-1">
                Month: {urgentDateMonth} 
                <span className="oi oi-circle-x ml-2" data-filter="urgentDateMonth" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {chosenStatus && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Status: {chosenStatus} 
                <span className="oi oi-circle-x ml-2" data-filter="chosenStatus" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {custId && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Cust ID: {custId} 
                <span className="oi oi-circle-x ml-2" data-filter="custId" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {phone && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Phone: {phone} 
                <span className="oi oi-circle-x ml-2" data-filter="phone" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            {orderGroupingFilter && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Group: {orderGroupingFilter} 
                <span className="oi oi-circle-x ml-2" data-filter="orderGroupingFilter" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
            { sort && sort.length > 0 && 
              <Badge pill variant="info" className="ml-1 mr-1">
                Sort: {sort.join(", ").replace(/_/g, " ")} 
                <span className="oi oi-circle-x ml-2" data-filter="sort" style={{cursor:'pointer'}}></span>
              </Badge> 
            }
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  Cust ID 
                  <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip className='text-left' id={`tooltip`}>
                          Click on Cust Id to see additional info
                        </Tooltip>
                      }
                    >
                     <span className="pointer oi oi-question-mark oi-question-mark-custom"></span>
                    </OverlayTrigger>
                </th>
                <th>Item ID</th>
                <th>Urgent Date</th>
                <th>FabID/Desc/Cont/Lin</th>
                <th>Item</th>
                <th>Status</th>
                <th onClick={handleOnSortClick}>
                  <span data-property={`itemInBy`} className='pointer mr-1'>ItemInBy</span> 
                  <SortArrows field={`itemInBy`} sort={sort}/>
                </th>
                <th style={{"width":"15%"}}>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {dataTableArray && Array.isArray(dataTableArray) ? 
                dataTableArray.map(item => {
                  const max120 = new Date().getTime() - new Date(item.time).getTime()
                  const Difference_In_Days = Math.round(max120 / (1000 * 3600 * 24))
                  
                  return (
                    <tr key={item.id}>
                      <td>
                          <span className="pointer" data-cust-id={item.custid} data-id={item.id} onClick={toggleRightSidebar}>{item.custid}</span>
                        </td>
                      <td>{item.id}</td>
                      <td>{urgentDateField(item)}</td>
                      <td>{item.item_desc}</td>
                      <td>{item.item_type}</td>
                      <td>{item.status}</td>
                      <td>{itemInByDateField(item)}</td>
                      <td>{item.remarks}</td>
                    </tr>
                  )
                }) : <></>
              }
            </tbody>
          </Table>
          {pagination ? 
          <GenericPagination 
            pagination={pagination} 
            rowLimit={rowLimit}
            handleOnPaginationClick={handleOnPaginationClick}
          ></GenericPagination> : <></>}
        </Col>
      </Row>

      <RightSidebar 
        show={showRightSidebar}
        closeFn={handleOnSidebarClose}
        state={orderItem}
        >
          <h5 className="text-center mb-2">Additional Info</h5>  
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New - Cust ID</td>
                <td>{orderItem.custid}</td>
              </tr>
              <tr>
                <td>Old - Cust ID</td>
                <td>{orderItem.cust_id}</td>
              </tr>
              <tr>
                <td>Order ID</td>
                <td>{orderItem.order_id}</td>
              </tr>
              <tr>
                <td>Group Name</td>
                <td>{orderItem.group_name}</td>
              </tr>
              <tr>
                <td>Consultant Name</td>
                <td>{orderItem.consultant_name}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{orderItem.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{orderItem.phone}</td>
              </tr>
              <tr>
                <td>DOS</td>
                <td>{DOSDDMMYYYYFormat(orderItem.time)}</td>
              </tr>
              <tr>
                <td>Max120 (days)</td>
                <td>{max120Field(orderItem)} </td>
              </tr>
              <tr>
                <td>Urgent Date</td>
                <td>{urgentDateField(orderItem)}</td>
              </tr>
            </tbody>
          </Table>
        </RightSidebar>
    </Layout>
  )
}

export default ConsultantOrders