import React, {useState, useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import RightSidebar from "../../../components/sidebar/rightSideBar"
import {Container, Table, Tooltip, OverlayTrigger, Form, Row, Col, Badge } from 'react-bootstrap'
import SEO from "../../../components/seo";
import Loader from '../../../components/loading';
import DatePickerFilter from '../../../components/datepickerFilter';
import GenericPagination from '../../../components/genericPagination';
import LocalStorage from "../../../components/localStorage";
import GroupingFilter from '../../../components/filters/groupingFilter';
import constants from '../../../helpers/constants';
import Fetch from "../../../helpers/fetch";
import {DOSDDMMYYYYFormat, DDMMYYYYFormat, YYYYMMDDFormat} from '../../../helpers/dateTime';
import Select from 'react-select'
import * as jwtDecode from 'jwt-decode';


const Suppliers = () => {
  // const currentPage = useRef();

  const [showLoader, setShowLoader] = useState(false);
  const [prodArray, setProdArray] = useState([]);
  const [pagination, setPagination] = useState({});
  const [orderItem, setOrderItem] = useState({});

  const [rowLimit, setRowLimit] = useState(25);
  const [page, setPage] = useState(1);


  const [dateFilter, setDateFilter] = useState('');
  const [itemTypeFilter, setItemTypeFilter] = useState('');
  const [month, setMonth] = useState('');
  const [months, setMonths] = useState([]);
  const selectedItemTypes = useRef('');
  const [showRightSidebar, setshowRightSidebar] = useState(false);
  const [orderGroupings, setOrderGroupings] = useState([]);
  const [orderGroupingFilter, setOrderGroupingFilter] = useState('');

  const getSupplierProdSheet= (limit = 25) => {
    generateMonths();
    setShowLoader(true);
    if (jwtDecode(LocalStorage.get('jwt')) && jwtDecode(LocalStorage.get('jwt')).id) {
      let queryString = '';
      let queryParams = [];
      let url = `${constants.BACKEND_URL}/order-items/in-production`

      if (page) {
        queryParams.push(`page=${page}`)
      }
      if (dateFilter) {
        queryParams.push(`dateFilter=${YYYYMMDDFormat(dateFilter)}`)
      }
      if(itemTypeFilter) {
        queryParams.push(`itemTypeFilter=${itemTypeFilter}`)
      }
      if(orderGroupingFilter) {
        queryParams.push(`tag=${orderGroupingFilter}`)
      }
      if(queryParams.length > 0) {
        queryString = `?${queryParams.join('&')}`
      }

      Fetch.get(`${url}${queryString}`)
      .then((data) => {
          let array = [...data.data];
          array.forEach((x) => {
          let itemInByDate = getItemInByFromObject(x.item_in_by)
          if (!itemInByDate) {
            itemInByDate = new Date(3000,12,31);
          }
            x.itemInByDate = new Date(itemInByDate);
          });
          array.sort((a,b) => a.itemInByDate > b.itemInByDate ? 1 : -1 );
          setProdArray(array)
          setPagination(data.pagination);
          setShowLoader(false);
          setshowRightSidebar(false);
      })
      // .catch((e) => {
      //   setShowLoader(false);
      //   navigate('/login');
      //   return false;
      // })
    }
  }
  
  const generateMonths=() => {
    const monthsArray = [];
    for (let i = 6; i >= 0; i--) {
      const now = new Date();
      const monthsAgo = new Date(now.setMonth(now.getMonth() - i)).toISOString().split("T")[0].split("-");
      const yymm = `${monthsAgo[0]}-${monthsAgo[1]}`
      monthsArray.push(yymm)
    }
    setMonths(monthsArray);    
  }

  const getStatus = (item) => {
    return(
       item.status && (
        <>
          {item.status}
        </>
      )
    )
  }

  const getGroupings = (params) => {
    Fetch.get(`${constants.BACKEND_URL}/order-groupings`)
    .then(res => {

      if(res.error) {
        // setShowToast(true);
        // seterrorMessage(res.error);
        return;
      }
      setOrderGroupings(res.data);
    })
  }

  useEffect(() => {
    // Client-side Runtime Data Fetching
    getGroupings()
  },[])
  
  useEffect(() => {
    getSupplierProdSheet(); 
  }, [page, dateFilter, itemTypeFilter, orderGroupingFilter])

  const getOrderItemFromProdArray = (orderItemId) => {
    if (prodArray.length > 0) {
      const orderItem = prodArray.filter(item => {
        return item.id == Number(orderItemId)
      })
      return orderItem[0];
    }
  }

  const handleDatePickerChange = (e) => {
    setDateFilter(e);
  }

  const groupedOptions = (params) => {
    return constants.ITEM_TYPES_GROUPED_OPTIONS;
  }

  const defaultValue = () => {
    let options2 = [];
    if (selectedItemTypes.current) {
      const options = selectedItemTypes.current.split(",");
      options.forEach((item) => {
        options2.push({
          value: item,
          label: item,
        })
      })
    }
    return options2;
  }
  
  const MultiSelect = () => (
    <Select 
      defaultValue={defaultValue()}
      isMulti 
      closeMenuOnSelect={false} 
      options={groupedOptions()}
      onChange={handleOnMultiSelectChange}
      onBlur={handleOnBlur}
    />
  )
  const handleOnMultiSelectChange = (values) => {
    if (!values) {
      selectedItemTypes.current ='';
      setItemTypeFilter('');
      return;
    }
    let itemTypesCopy =[];
    let valuesCopy = [...values];
    if (Array.isArray(valuesCopy) && valuesCopy.length > 0) {
      valuesCopy.forEach(value => {
        itemTypesCopy.push(value.value)
      })
    }
    selectedItemTypes.current = itemTypesCopy.join(",");
  }

  const handleOnBlur = () => {
    setItemTypeFilter(selectedItemTypes.current);
  }

  const handleOnClearAllFilter = (e) => {
    selectedItemTypes.current ='';
    setItemTypeFilter('')
    setDateFilter('')
    setOrderGroupingFilter('');
  }

  const handleOnFilterPillClick = (e) => {
    const filter = e.target.dataset.filter
    switch (filter) {
      case 'itemTypeFilter':
        selectedItemTypes.current ='';
        setItemTypeFilter('');
        break;
      case 'dateFilter':
        setDateFilter('');
        break;
      case 'orderGroupingFilter':
        setOrderGroupingFilter('');
        break;
      default:
        break;
    }
  }

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const itemInByDateField = (item) => {
    const itemInBy = getItemInByFromObject(item.item_in_by)
      return (
        <>
          {DDMMYYYYFormat(itemInBy)}
        </>
      )
  }

  const getItemInByFromObject = (itemInByString) => {
    const itemInByObject = itemInByString ? JSON.parse(itemInByString) : null
    if (itemInByObject) {
      const itemInBy = itemInByObject.changedDate ? 
      new Date(itemInByObject.changedDate) : 
      new Date(itemInByObject.original);

      return itemInBy;
    }
  }

  const daysLeftField = (item) => {

    let daysLeft = '--';
    const itemInBy = getItemInByFromObject(item.item_in_by)

    if (itemInBy) {
      daysLeft = Math.ceil( (itemInBy.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
    }
    return (
      <>
        {daysLeft.toString()}
      </>
    )
    
  }

  const max120Field = (item) => {
    const max120 = new Date().getTime() - new Date(item.time).getTime()
    const Difference_In_Days = Math.round(max120 / (1000 * 3600 * 24))

    return Difference_In_Days ? Difference_In_Days : '--';
  }

  const urgentDateField = (item) => {
    let urgentDate = item.urgent_date;
    if (!urgentDate || urgentDate === '0000-00-00') {
      return;
    }
    urgentDate = new Date(urgentDate.split('T')[0]).toLocaleString("en-SG").split(",")[0];
    return (
      <>
        {urgentDate}
      </>
    )
  }
    
  const toggleRightSidebar = (e) => {
    setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
    if (Number(e.target.dataset.custId) !== Number(orderItem.custid)) {
      setshowRightSidebar(!showRightSidebar)
      setshowRightSidebar(true);
    } else {
      setshowRightSidebar(!showRightSidebar)
    }
  }

  const handleOnSidebarClose = () => {
    setshowRightSidebar(false);
  }

  const handleOnGroupingChange = (e) => {
    setOrderGroupingFilter(e.target.value);
    setPage(1);
  }

  const tableBody = () => {
    let tempId = 0;
    let whiteColor = true;
    return prodArray.map((item, index) => {
      if (index !== 0) {
        if (tempId !== item.custid) whiteColor = !whiteColor;
      }
      const rowBg = whiteColor ? 'odd' : 'even';
      const result = (          
        <tr key={item.id} className={`${rowBg}`}>
          <td>
            <span className="pointer" data-cust-id={item.custid} data-id={item.id} onClick={toggleRightSidebar}>{item.custid}</span>
          </td>
          <td>{item.id}</td>
          <td>{item.consultant_name}</td>
          <td>{item.item_desc}</td>
          <td>{item.item_type}</td>
          <td>{getStatus(item)}</td>
          <td>{itemInByDateField(item)}</td>
          <td>{daysLeftField(item)}</td>
          <td>{item.remarks}</td>
        </tr>
      )
      tempId = item.custid;
      return result;
    })
  }

  return (
    <Layout pageInfo={{ pageName: "itemsInProduction" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Items In Production page" />
      <Container fluid >
        <Row>
          <Col className="text-center mb-2">
            <h5>Items In Production</h5>
            <h6><small>- Showing Items In Production Only -</small></h6>
          </Col>
        </Row>
        <Row>
          <GroupingFilter
            show={true}
            orderGroupingFilter={orderGroupingFilter}
            handleOnGroupingChange={handleOnGroupingChange}
            orderGroupings={orderGroupings}
            withLabels
          ></GroupingFilter>
          <Col>
            <DatePickerFilter 
              datePickerLabel= {'ItemInBy Filter'} 
              selected={dateFilter}
              handleDatePickerChange={handleDatePickerChange}>
            </DatePickerFilter>
          </Col>
          <Col>
            <Form.Group as={Row}>
              <Form.Label column sm={4} lg={4} className='text-right'>
                Item Type
              </Form.Label>
              <Col sm={8} lg={8} className="text-left">
                <MultiSelect ></MultiSelect>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a></small>
            <span onClick={handleOnFilterPillClick}>
              {dateFilter && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Date Filter: {DDMMYYYYFormat(dateFilter)} 
                  <span className="oi oi-circle-x ml-2" data-filter="dateFilter" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {orderGroupingFilter && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Group: {orderGroupingFilter} 
                  <span className="oi oi-circle-x ml-2" data-filter="orderGroupingFilter" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {itemTypeFilter && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Selected Types: {itemTypeFilter} 
                  <span className="oi oi-circle-x ml-2" data-filter="itemTypeFilter" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
            
            </span>
          </Col>
        </Row>
        <Table responsive>
          <thead>
            <tr>
            <th>
                Cust ID 
                <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className='text-left' id={`tooltip`}>
                        Click on Cust Id to see additional info
                      </Tooltip>
                    }
                  >
                    <span className="pointer oi oi-question-mark oi-question-mark-custom"></span>
                  </OverlayTrigger>
              </th>
              <th>Item ID</th>
              <th>Consultant Name</th>
              <th>FabID/Desc/Cont/Lin</th>
              <th>Item</th>
              <th>Status</th>
              <th>ItemInBy</th>
              <th>Days Left</th>
              <th style={{"width":"15%"}}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {prodArray && Array.isArray(prodArray)  ? 
              tableBody() : <tr></tr>
            }
          </tbody>
        </Table>
        {pagination ? 
        <GenericPagination 
          pagination={pagination} 
          rowLimit={rowLimit}
          handleOnPaginationClick={handleOnPaginationClick}
        ></GenericPagination> : <></>}
      </Container>
      <RightSidebar 
        show={showRightSidebar}
        closeFn={handleOnSidebarClose}
        state={orderItem}
        >
          <h5 className="text-center mb-2">Additional Info</h5>  
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New - Cust ID</td>
                <td>{orderItem.custid}</td>
              </tr>
              <tr>
                <td>Old - Cust ID</td>
                <td>{orderItem.cust_id}</td>
              </tr>
              <tr>
                <td>Order ID</td>
                <td>{orderItem.order_id}</td>
              </tr>
              <tr>
                  <td>Group Name</td>
                  <td>{orderItem.group_name}</td>
                </tr>
              <tr>
                <td>Consultant Name</td>
                <td>{orderItem.consultant_name}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{orderItem.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{orderItem.phone}</td>
              </tr>
              <tr>
                <td>DOS</td>
                <td>{DOSDDMMYYYYFormat(orderItem.time)}</td>
              </tr>
              <tr>
                <td>Max120 (days)</td>
                <td>{max120Field(orderItem)} </td>
              </tr>
              <tr>
                <td>Urgent Date</td>
                <td>{urgentDateField(orderItem)}</td>
              </tr>
            </tbody>
          </Table>
        </RightSidebar>
    </Layout>
  )
}

export default Suppliers