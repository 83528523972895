import React, {useState, useEffect} from 'react';
import {Table, Container, Form, Tooltip, OverlayTrigger, Row, Col, Badge} from 'react-bootstrap'
import Layout from "../../../components/layout"
import RightSidebar from "../../../components/sidebar/rightSideBar"
import SEO from "../../../components/seo";
import GenericPagination from '../../../components/genericPagination';
import ConsultantFilter from '../../../components/filters/consultantFilter';
import Loader from '../../../components/loading/';
import DatePickerFilter from '../../../components/datepickerFilter';

import Fetch from '../../../helpers/fetch';
import {generateMonths} from '../../../helpers/dateTime';
import constants from '../../../helpers/constants';
import { isAdmin, isManager, isConsultant, getUsernameFromToken } from '../../../helpers/roles'
import {DOSDDMMYYYYFormat, DDMMYYYYFormat, YYYYMMDDFormat} from '../../../helpers/dateTime';


const ConsultantSalesReport = ({location}) => {
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [consultants, setConsultants] = useState([]);
  const [consultantFilter, setConsultantFilter] = useState(isConsultant() || isManager()  ? getUsernameFromToken() : '');
  const [page, setPage] = useState(1)
  const [month, setMonth] = useState(location && location.state && location.state.month ? location.state.month : '');
  const [months, setMonths] = useState(generateMonths(12));
  const [status, setStatus] = useState(location && location.state && location.state.status ? location.state.status : '');
  const [statuses, setStatuses] = useState(['Completed', 'Open']);
  const [showLoader, setShowLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderItem, setOrderItem] = useState({});
  const [totalAmount, setTotalAmount] = useState(0)
  const [showRightSidebar, setshowRightSidebar] = useState(false)
  const [primoCount, setPrimoCount] = useState(0)
  const [recordCount, setRecordCount] = useState(0)
  const [startDate, setStartDate] = useState(location && location.state && location.state.startDate ? location.state.startDate : '');
  const [endDate, setEndDate] = useState(location && location.state && location.state.endDate ? location.state.endDate : new Date())

  const getOrders = () => {
    setShowLoader(true);
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/orders`

    if (page) {
      queryParams.push(`page=${page}`)
    }
    if (month) {
      queryParams.push(`month=${month}`)
    }
    if (status) {
      queryParams.push(`status=${status}`)
    }
    if (consultantFilter) {
      queryParams.push(`name=${consultantFilter}`)
    }
    if (startDate) {
      queryParams.push(`startDate=${YYYYMMDDFormat(startDate)}`)
    }
    if (endDate) {
      queryParams.push(`endDate=${YYYYMMDDFormat(endDate)}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }

    Fetch.get(`${url}${queryString}`)
    .then((data) => {
      setOrders(data.data)
      setTotalAmount(data.totalAmount.totalAmount)
      setPagination(data.pagination);
      setPrimoCount(data.noOfPrimo.count)
      setRecordCount(data.pagination.count)
      setShowLoader(false);
      setshowRightSidebar(false);
    }).catch((e) => {
      setShowLoader(false);
      // navigate('/login');
      return false;
    })
  }

  const handleOnConsultantChange = (e) => {
    setConsultantFilter(e.target.value);
    setPage(1);
  }

  const handleOnMonthChange = (e) => {
    setMonth(e.target.value);
  }

  const handleOnStatusChange = (e) => {
    setStatus(e.target.value);
  }

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const handleOnClearAllFilter = (e) => {
    setPage(1);
    if (!isConsultant()) setConsultantFilter('');
    setMonth('');
    setStatus('');
  }

  const handleOnFilterPillClick = (e) => {
    const filter = e.target.dataset.filter
    switch (filter) {
      case 'consultantFilter':
        !isConsultant() ? setConsultantFilter('') : setConsultantFilter(getUsernameFromToken());
        break;
      case 'month':
        setMonth('')
        break;
      case 'status':
        setStatus('')
        break;
      case 'startDate':
        setStartDate('')
        break;
      case 'endDate':
        setEndDate('')
        break;
      default:
        break;
    }
  }

  const toggleRightSidebar = (e) => {
    setOrderItem(getItemsFromOrders(e.target.dataset.id))
    if (Number(e.target.dataset.custId) !== Number(orderItem.custid)) {
      setshowRightSidebar(!showRightSidebar)
      setshowRightSidebar(true);
    } else {
      setshowRightSidebar(!showRightSidebar)
    }
  }

  const getItemsFromOrders = (orderItemId) => {
    if (orders.length > 0) {
      const orderItem = orders.filter(item => {
        return item.id == Number(orderItemId)
      })
      return orderItem[0];
    }
  }

  const handleOnSidebarClose = () => {
    setshowRightSidebar(false);
  }

  useEffect(() => {
    getConsultants()
  },[]);

  useEffect(() => {
    getOrders();
  },[page, status, month, consultantFilter, startDate, endDate])

  const getConsultants = () => {
    let url = '';
    if (isConsultant()) {
      url = `${constants.BACKEND_URL}/consultants/${getUsernameFromToken()}`
    } else {
      url = `${constants.BACKEND_URL}/consultants`
    }

    Fetch.get(url)
      .then(data => {
        if (data) setConsultants(data)
      })
  }

  const orderTypeField = (item) => {
    const isPrimo = item && item.order_type && item.order_type.indexOf('PRIMO') > -1;
    return (
      <>
        { isPrimo ? '1' : '0'}
      </>
    )
  }

  const orderAmountField = (item) => {
    
    return (
      <>

        {Number(Number(item.deposit) + Number(item.balance)).toFixed(2)}
      </>
    )
  }

  const statusField = (item) => {
    return (
      <>
        {item && item.fully_paid ? (
          <Badge pill variant="success">Fully Paid</Badge>
        ) : (
          <Badge pill variant="warning">Open</Badge>
        )}
      </>
    )
    
  }

  const max120Field = (item) => {
    const max120 = new Date().getTime() - new Date(item.time).getTime()
    const Difference_In_Days = Math.round(max120 / (1000 * 3600 * 24))

    return Difference_In_Days ? Difference_In_Days : '--';
  }

  const handleStartDatePickerChange = (e) => {
    setStartDate(e)
  }

  const handleEndDatePickerChange = (e) => {
    setEndDate(e)
  }

  return (
   <Layout pageInfo={{ pageName: "consultantSalesReport" }}>
     <Loader show={showLoader}></Loader>
     <SEO title="Sales Tracking" />
     <Row>
        <Col className="text-center mb-2">
          <h5>Sales Tracking</h5>
          <h6><small>- Showing Open/Fully Paid Orders -</small></h6>
        </Col>
      </Row>
      <Container>
        <Row>
          {isAdmin() && 
            <ConsultantFilter
              show={true}
              consultants={consultants}
              handleOnChange={handleOnConsultantChange}
              consultantFilter={consultantFilter}
            ></ConsultantFilter>
          }
          <Col>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>Month</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" value={month} onChange={handleOnMonthChange}>
                  <option value=''>--Select Month--</option>
                  {
                    months.map(x => {
                      return (
                        <option key={x} >{x}</option>
                      )
                    })
                  }
                </Form.Control>
              </Col>
              
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>Status</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" value={status} onChange={handleOnStatusChange}>
                  <option value=''>--Select Status--</option>
                  {
                    statuses.map(x => {
                      return (
                        <option key={x} >{x}</option>
                      )
                    })
                  }
                </Form.Control>
              </Col>
              
            </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col sm={6} md={4} lg={4}> 
            <DatePickerFilter 
              datePickerLabel= {'Start Date'} 
              selected={startDate}
              handleDatePickerChange={handleStartDatePickerChange}
             >
            </DatePickerFilter>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <DatePickerFilter 
              datePickerLabel= {'End Date'} 
              selected={endDate}
              handleDatePickerChange={handleEndDatePickerChange}
            >
            </DatePickerFilter>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a> </small>
            <span onClick={handleOnFilterPillClick}>
              {consultantFilter && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Consultant: {consultantFilter} 
                  <span className="oi oi-circle-x ml-2" data-filter="consultantFilter" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {month && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Month: {month} 
                  <span className="oi oi-circle-x ml-2" data-filter="month" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {status && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Status: {status} 
                  <span className="oi oi-circle-x ml-2" data-filter="status" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
               {startDate && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Start Date: {DDMMYYYYFormat(startDate)}  
                  <span className="oi oi-circle-x ml-2" data-filter="startDate"  style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {endDate && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  End Date: {DDMMYYYYFormat(endDate)}  
                  <span className="oi oi-circle-x ml-2" data-filter="endDate"  style={{cursor:'pointer'}}></span>
                </Badge> 
              }
            </span>
          </Col>
        </Row>
        <Row className="mb-2 text-right">
          <Col>
              <h5>Total Amount: {totalAmount ? totalAmount.toLocaleString('en-SG', {
                style: 'currency',
                currency: 'SGD',
              }) : 0}</h5>
          </Col>
        </Row>
        <Row >
          <Col>
            <Table responsive className="text-center">
              <thead>
                <tr>
                  <th>DOS</th>
                  <th>Completion Date</th>
                  <th>
                    Cust ID
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip className='text-left' id={`tooltip`}>
                          Click on Cust Id to see additional info
                        </Tooltip>
                      }
                    >
                      <span className="pointer oi oi-question-mark oi-question-mark-custom"></span>
                    </OverlayTrigger>

                  </th>
                  <th>Order ID</th>
                  <th>Primo ({primoCount} out of {recordCount})</th>
                  <th className="text-right">Order Amount ($)</th>
                </tr>
              </thead>
              <tbody>
                { orders && Array.isArray(orders) ? 
                  orders.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>{DOSDDMMYYYYFormat(item.time)}</td>
                        <td>{DDMMYYYYFormat(item.balance_paid) === "--" ? "" : DDMMYYYYFormat(item.balance_paid)}</td>
                        <td>
                          <span className="pointer" data-cust-id={item.custid} data-id={item.id} onClick={toggleRightSidebar}>{item.custid}</span>
                        </td>
                        <td>{item.id}</td>
                        <td>{orderTypeField(item)}</td>
                        <td className="text-right">{orderAmountField(item)}</td>
                      </tr>
                    )
                  }) : ''
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        {pagination ? 
        <GenericPagination 
          pagination={pagination} 
          rowLimit={rowLimit}
          handleOnPaginationClick={handleOnPaginationClick}
        ></GenericPagination> : <></>}
      </Container>
      <RightSidebar 
        show={showRightSidebar}
        closeFn={handleOnSidebarClose}
        state={orderItem}
        >
          <h5 className="text-center mb-2">Additional Info</h5>  
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New - Cust ID</td>
                <td>{orderItem.custid}</td>
              </tr>
              <tr>
                <td>Old - Cust ID</td>
                <td>{orderItem.cust_id}</td>
              </tr>
              <tr>
                <td>Order ID</td>
                <td>{orderItem.id}</td>
              </tr>
              <tr>
                <td>Group Name</td>
                <td>{orderItem.group_name}</td>
              </tr>
              <tr>
                <td>Consultant Name</td>
                <td>{orderItem.consultant_name}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{orderItem.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{orderItem.phone}</td>
              </tr>
              <tr>
                <td>DOS</td>
                <td>{DOSDDMMYYYYFormat(orderItem.time)}</td>
              </tr>
              <tr>
                <td>Max120 (days)</td>
                <td>{max120Field(orderItem)} </td>
              </tr>              
            </tbody>
          </Table>
        </RightSidebar>
   </Layout>

  )
}

export default ConsultantSalesReport