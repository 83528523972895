import React from 'react';

const SortArrows = ({field, sort}) => {
  let newDirection = '';
  if (sort.length > 0) {
    for (let i = 0; i < sort.length; i++) {
      const element = sort[i];
      const fieldFromSortArray = element.split("_")[0];
      const direction = element.split("_")[1];
      if (field === fieldFromSortArray) {
        newDirection = direction;
        break;
      }
    }
  }
  return (
    <>
      <div className='d-inline-block'>
        {newDirection === '' && 
          <>
            <span className='oi oi-chevron-top text-muted' title="Click to sort">
            </span>
            <span className='oi oi-chevron-bottom text-muted' title="Click to sort">
            </span>
          </>
        }
        {newDirection === 'ASC' && <span className='oi oi-chevron-top'></span>}
        {newDirection === 'DESC' && <span className='oi oi-chevron-bottom'></span>}
      </div>
    </>
  )
}

export default SortArrows;