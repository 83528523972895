import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import {Table, Container, Row, Col} from 'react-bootstrap'
import SEO from "../../../components/seo";
import Layout from "../../../components/layout";
import Loader from '../../../components/loading';
import GenericPagination from '../../../components/genericPagination';

import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';

const RefundedItemsList = () => {
  const [showLoader, setShowLoader] = useState(false)
  const [refundedItems, setRefundedItems] = useState([])
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [page, setPage] = useState(1);


  const getRefundedItems = (params) => {
    let queryString = '';
    let queryParams = [];
    
    if(page) {
      queryParams.push(`page=${page}`)
    }
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    setShowLoader(true)
    const url=`${constants.BACKEND_URL}/order-items/refunded`
    Fetch.get(`${url}${queryString}`).then(data => {
     setRefundedItems(data.data)
     setPagination(data.pagination)
     setShowLoader(false);

    })
  }

  const remarksField = (item) => {
    return (
      <>
        {
          item.refund_remarks ? (
            <div className="pointer text-wrap" style={{"whiteSpace": "pre-line"}}>
              {item.refund_remarks.split ('\n').map (
                (line, i) => {
                  return <div data-id={item.id} key={i}>{line}</div> 
                }
              )}
            </div>
          ) : (
            <></>
          )
        }
      </>
    )
  }
  useEffect(() => {
    getRefundedItems()
    return () => {
      
    }
  }, [page])

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }
  return (
    <Layout pageInfo={{ pageName: "refundedItems" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Refunded Items page" />
      <Row>
        <Col className="text-center mb-2">
          <h5>Refunded Items</h5>
          <h6><small>- Approved Refund Request -</small></h6>
        </Col>
      </Row>
      <Container>
        <Table>
          <thead>
            <tr>
              <th>Cust ID</th>
              <th>Order ID</th>
              <th>Item ID</th>
              <th>Fabric</th>
              <th>Item Type</th>
              <th className="text-right">Price</th>
              <th>Status</th>
              <th>Refund Reason</th>
            </tr>
          </thead>
          <tbody>
            {refundedItems && Array.isArray(refundedItems) && refundedItems.length > 0 ?
              refundedItems.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.custid}</td>
                    <td><Link to={`/orders/salesOrder?custId=${item.custid}&orderId=${item.order_id}`}>{item.order_id}</Link></td>
                    <td>{item.id}</td>
                    <td>{item.item_desc}</td>
                    <td>{item.item_type}</td>
                    <td className="text-right">${Number(item.priceAfterDiscount).toFixed(2)}</td>
                    <td>{item.refund_status.toUpperCase()}</td>
                    <td>{remarksField(item)}</td>
                  </tr>
                )
              }) : <><tr><td>No Refund Items</td></tr></>
            }
          </tbody>
        </Table>
        {pagination ? 
        <GenericPagination 
          pagination={pagination} 
          rowLimit={rowLimit}
          handleOnPaginationClick={handleOnPaginationClick}
        ></GenericPagination> : <></>}
      </Container>
    </Layout>
  )
}

export default RefundedItemsList  