import React from 'react';
import {Form, Row, Col} from 'react-bootstrap'
import { isAdmin, isManager } from '../../helpers/roles'

const ConsultantFilter = ({show, consultantFilter, handleOnChange, consultants, withLabels}) => {

  return (
    (isAdmin() || isManager()) &&
      <Col>
        <Form.Group as={Row} >
          {withLabels && <Form.Label column sm={3}>Consultant</Form.Label>}
          <Col>
            <Form.Control as="select" value={consultantFilter} onChange={handleOnChange}>
              <option key="firstInLine" value=''>--Select Consultant--</option>
              {
                consultants && Array.isArray(consultants) ? consultants.map(x => {
                  return (
                    <option key={x.name} >{x.name}</option>
                  )
                }) : <></>
              }
            </Form.Control>
          </Col>
        </Form.Group>
      </Col>
  )
}

export default ConsultantFilter