import React, { useEffect } from 'react'
import {Pagination} from 'react-bootstrap';

const GenericPagination = ({pagination, rowLimit, handleOnPaginationClick}) => {
  let items = [];
  const {currentPage, totalPages} = pagination;

  const isActive = (number) => {
    return Number(number) === Number(currentPage)
  }
  
  // 1 - 5 pages exactly
  if (pagination && currentPage < 5 && totalPages <= 5 ) {
    if (currentPage !== totalPages) {
      for (let number = 1; number <= totalPages-1; number++) {
        items.push(
          <Pagination.Item 
            key={number} 
            active={isActive(number)} 
            data-go-to={number}
          >
            {number}
          </Pagination.Item>,
        );
      }
    }
  }

  // current pages < 5 and 1 - xx pages
  if (pagination && currentPage < 5 && totalPages > 5) {
    for (let number = 1; number <= 5; number++) {
      items.push(
        <Pagination.Item 
          key={number} 
          active={isActive(number)} 
          data-go-to={number}
        >
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Ellipsis key={currentPage + 'zz' + 1}/>)
  }
  
  // Middle part after 5 pages
  // ... 5 6 7 8 9 ....
  if (pagination && currentPage >= 5 
      && currentPage <= totalPages-5 
    ) {
    if (currentPage !== 5) {
      items.push(<Pagination.Ellipsis key={currentPage-5}/>)
    }
    for (let number = currentPage - 4; number <= currentPage + 4; number++) {

      items.push(
        <Pagination.Item 
          key={number} 
          active={isActive(number)} 
          data-go-to={number}
        >
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Ellipsis key={currentPage+5}/>)
  }

  if (pagination && (
      currentPage > totalPages - 5 && 
      currentPage <= totalPages &&
      currentPage >= 5)
  ) {
    items.push(<Pagination.Ellipsis />)
    if (Number(currentPage) !== Number(totalPages)) {
      for (let number = totalPages -5; number < totalPages; number++) {
      
        items.push(
          <Pagination.Item 
            key={number} 
            active={isActive(number)} 
            data-go-to={number}
          >
            {number}
          </Pagination.Item>,
        );
      }
    }
  }

  if (pagination && currentPage === totalPages) {
    for (let number = totalPages - 5; number < totalPages; number++) {
      if (number > 0) {
        items.push(
          <Pagination.Item 
            key={number} 
            active={isActive(number)} 
            data-go-to={number}
          >
            {number}
          </Pagination.Item>,
        );
      }
    }
  }

  return (
    <div id="pagination">
      {totalPages > 1 ? (
        <Pagination onClick={handleOnPaginationClick}>
          {
            currentPage > 1 ? (
              <>
                <Pagination.First 
                  key='first' 
                  id="pagination-first" 
                  data-go-to ="firstPage" 
                  />
                <Pagination.Prev 
                  key='previous' 
                  id="pagination-prev" 
                  data-go-to="prevPage" 
                  />
              </>
            ) : <></>
          }
          { items }
          <Pagination.Item 
            data-go-to={totalPages} 
            active={pagination.currentPage === pagination.totalPages}
            >
            {pagination && totalPages ? totalPages : ''}
          </Pagination.Item>
          {
            currentPage < totalPages ? (
              <>
                <Pagination.Next 
                  key='next' 
                  id="pagination-next" 
                  data-go-to="nextPage" 
                  />
                <Pagination.Last 
                  key='last' 
                  id="pagination-last" 
                  data-go-to="lastPage" 
                  />
              </>
            ) : <></>
          }
        </Pagination>

      ) : <></>}
    </div>
  )
  

}

export default GenericPagination