import React, {useState, useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import {Container, Table, Row, Col, Badge } from 'react-bootstrap'
import SEO from "../../../components/seo";
import Loader from '../../../components/loading';
import DatePickerFilter from '../../../components/datepickerFilter';
import GenericPagination from '../../../components/genericPagination';
import LocalStorage from "../../../components/localStorage";
import constants from '../../../helpers/constants';
import Fetch from "../../../helpers/fetch";
import {DDMMYYYYFormat, YYYYMMDDFormat} from '../../../helpers/dateTime';
import * as jwtDecode from 'jwt-decode';


const FabricOrder = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [prodArray, setProdArray] = useState([]);
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [dateFilter, setDateFilter] = useState(new Date());

  useEffect(() => {
    getFabricOrders(); 
  }, [page, dateFilter])

  const getFabricOrders= (limit = 25) => {
    setShowLoader(true);
    if (jwtDecode(LocalStorage.get('jwt')) && jwtDecode(LocalStorage.get('jwt')).id) {
      let queryString = '';
      let queryParams = [];
      let url = `${constants.BACKEND_URL}/order-items/fabric-order`

      if (page) {
        queryParams.push(`page=${page}`)
      }
      if (dateFilter) {
        queryParams.push(`dateFilter=${YYYYMMDDFormat(dateFilter)}`)
      }
      
      if(queryParams.length > 0) {
        queryString = `?${queryParams.join('&')}`
      }

      Fetch.get(`${url}${queryString}`)
      .then((data) => {
          setProdArray(data.data)
          setPagination(data.pagination);
          // currentPage.current = data.pagination.currentPage;
          setShowLoader(false);
      })
      // .catch((e) => {
      //   setShowLoader(false);
      //   navigate('/login');
      //   return false;
      // })
    }
  }

  const handleDatePickerChange = (e) => {
    setDateFilter(e);
  }

  const handleOnClearAllFilter = (e) => {
    setDateFilter(new Date())
  }

  const handleOnFilterPillClick = (e) => {
    const filter = e.target.dataset.filter
    switch (filter) {
      case 'dateFilter':
        setDateFilter(new Date());
        break;
      default:
        break;
    }
  }

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const getItemInByFromObject = (itemInByString) => {
    const itemInByObject = itemInByString ? JSON.parse(itemInByString) : null
    if (itemInByObject) {
      const itemInBy = itemInByObject.changedDate ? 
      new Date(itemInByObject.changedDate) : 
      new Date(itemInByObject.original);

      return itemInBy;
    }
  }

  return (
    <Layout pageInfo={{ pageName: "fabricOrder" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Fabric Order page" />
      <Container >
        <Row>
          <Col className="text-center mb-2">
            <h5>Fabric Order Report</h5>
            <h6><small>- Showing Fabric Description -</small></h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <DatePickerFilter 
              datePickerLabel= {'Date Of Sale Filter'} 
              selected={dateFilter}
              handleDatePickerChange={handleDatePickerChange}>
            </DatePickerFilter>
          </Col>
          
        </Row>
        <Row className="mb-3">
          <Col>
            <small><a href="#" onClick={handleOnClearAllFilter}>Filter to Today</a></small>
            <span onClick={handleOnFilterPillClick}>
              {dateFilter && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Date Filter: {DDMMYYYYFormat(dateFilter)} 
                  <span className="oi oi-circle-x ml-2" data-filter="dateFilter" style={{cursor:'pointer'}} title="Filter to Today"></span>
                </Badge> 
              }
            </span>
          </Col>
        </Row>
        <Table responsive>
          <thead>
            <tr>
              <th>Cust ID</th>
              <th>Item ID</th>
              <th>FabID/Desc/Cont/Lin</th>
            </tr>
          </thead>
          <tbody>
            {prodArray && Array.isArray(prodArray) ? 
              prodArray.map(item => {
                
                return (
                  <tr key={item.id}>
                    <td>{item.custid}</td>
                    <td>{item.id}</td>
                    <td>{item.item_desc}</td>
                  </tr>
                )
              }) : <tr></tr>
            }
          </tbody>
        </Table>
        {pagination ? 
        <GenericPagination 
          pagination={pagination} 
          rowLimit={rowLimit}
          handleOnPaginationClick={handleOnPaginationClick}
        ></GenericPagination> : <></>}
      </Container>      
    </Layout>
  )
}

export default FabricOrder